<div class="hero">
  <div class="hero__frame">
    <div class="hero__pre">
      <div class="hero__pre-icon">
        <img src="/assets/images/butterfly-logo.svg" width="48" height="36" alt="">
      </div>
      <div class="hero__pre-text">
        <strong class="hero__pre-title">Airglass</strong>
      </div>
    </div>
    <h1 class="hero__title">
      A New Class of Software to Enable
      <span class="gradient-text hero__title--lg">a Unified Reality Experience</span>
    </h1>


    <div class="hero__request-demo action-glow">
      <button
        mb-button
        mb-button-text-large
        [isTertiary]="true"
        (click)="requestDemo()"
        [text]="'Join Beta'"
      ></button>
    </div>

    <!-- <div class="hero__sub-text">
      <p>Airglass allows you to be <strong>seen</strong>, <strong>connected</strong>, and <strong>understood</strong>.</p>
    </div> -->
    <!-- <div class="hero__actions action-glow">
      <button
        mb-button
        mb-button-text-large
        [isTertiary]="true"
        text="Download Airglass"
      ></button>
    </div> -->
  </div>
</div>
