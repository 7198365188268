import { Component } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent {
  requestDemo() {
    const element = document.createElement('a');
    element.href = 'https://mobeus-marketing365.powerappsportals.com/join-beta/';
    element.target = '_blank';
    element.click();
    element.remove();
  }
}
