import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gift-banner',
  templateUrl: './gift-banner.component.html',
  styleUrls: ['./gift-banner.component.scss']
})

export class GiftBannerComponent {
  @Input() demo = false;
  isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;


  public onAction() {
    // if (this.demo) {
    //   const platform = (window as any).navigator?.userAgentData?.platform ?? window.navigator.platform;

    //   const element = document.createElement('a');
    //   element.href = /mac/i.test(platform) ? '//download.mobeus.com/Airglass.pkg' : '//downloads.mobeus.com/airglass/win_x64/Airglass-setup.exe'

    //   element.click();
    // } else {
      const element = document.createElement('a');
      element.href = 'https://mobeus-marketing365.powerappsportals.com/join-beta/';
      element.target = '_blank';
      element.click();
      element.remove();
    }
  }
// }
